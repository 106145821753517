import React, { FC } from "react";
import styled from "styled-components";
import { Box } from "@myonlinestore/bricks";

const StyledWrapper = styled(Box)`
  position: relative;
  min-height: 500px;
  width: 100%;

  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    margin: 0;
  }
`;

const StyledImage = styled.div`
  background-image: url("https://images.unsplash.com/photo-1558494949-ef010cbdcc31?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1782&q=80");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 450px;
  width: 900px;

  @media screen and (max-width: 960px) {
    width: auto;
    height: 300px;
    box-sizing: border-box;
  }
`;

const StyledContentWrapper = styled(Box)`
  background-color: #fff;
  background-repeat: no-repeat;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  height: 350px;
  justify-content: center;
  max-width: 700px;
  padding-right: 0px;
  padding: 0 60px;
  position: absolute;
  right: 0;
  top: 150px;

  @media screen and (max-width: 960px) {
    border: none;
    height: initial;
    max-width: initial;
    padding: 0 0 24px 0;
    position: initial;
    top: 0px;
  }
`;

const TextOnImage: FC = props => (
  <StyledWrapper
    position="relative"
    minHeight="500px"
    width="100%"
  >
    <StyledImage />
    <StyledContentWrapper>{props.children}</StyledContentWrapper>
  </StyledWrapper>
);

export default TextOnImage;
