import styled from "styled-components";

const Input = styled.input`
  width: 100%;
  border: none;
  padding: 6px;
  outline: none;
  font-size: 13px;
  margin: 6px 0 6px 0;
  box-sizing: border-box;
`;

export default Input;
