import React, { FC } from "react";
import styled from "styled-components";
import Link from "../Link";

const StyledMenuWrapper = styled.header`
  align-items: center;
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 120px;
  justify-content: space-between;
  position: relative;
  width: 100%;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin: auto;
    text-align: center;
  }

  & img {
    height: 80px;
    margin: 20px 0;

    @media screen and (max-width: 600px) {
      margin: auto;
    }
  }
`;

const StyledMenu = styled.nav`
  text-align: right;

  @media screen and (max-width: 600px) {
    text-align: center;
    margin: auto;
  }

  ul {
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: flex-start;
    padding: 3px;
    flex-wrap: wrap;

    @media screen and (max-width: 600px) {
      border-bottom: none;
      justify-content: center;
    }
  }

  li {
    display: block;
    margin-bottom: 0;
    position: relative;
    transform: none;
    white-space: nowrap;
  }

  a {
    font-size: 12px;
    letter-spacing: .35px;
    line-height: 22px;
    padding: 0 20px;
    text-decoration: none;
    text-transform: uppercase;

    @media screen and (max-width: 600px) {
      padding: 0 6px;
      letter-spacing: .3px;
    }

    @media screen and (min-width: 1440px) {
      padding: 0 30px;
    }
  }
`;

const Menu: FC = () => (
  <StyledMenuWrapper>
    <img
      alt="logo"
      src={require("../../assets/images/care4it.png")}
      height="80px"
    />
    <StyledMenu>
      <ul>
        <li>
          <Link href="#about">Over CFI</Link>
        </li>
        <li>
          <Link href="#teamviewer">Teamviewer</Link>
        </li>
        <li>
          <Link href="#partners">Partners</Link>
        </li>
        <li>
          <Link href="#contact">Contact</Link>
        </li>
      </ul>
    </StyledMenu>
  </StyledMenuWrapper>
);

export default Menu;
