import React, { FC } from "react";
import styled from "styled-components";
import { Text } from "@myonlinestore/bricks";
import Section from "../Section";

const StyledFooterIcon = styled.img`
  height: 50px;
  margin: 24px auto;
`;

const StyledFooterWrapper = styled.div`
  max-width: 1400px;
  margin: 24px;
  text-align: center;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  ul {
    list-style: none;
    flex-wrap: wrap;
    padding: 16px;
    display: flex;
    width: 100%;
    margin: 0;
  }

  li {
    list-style: none;
    text-align: left;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;

const FooterText = styled(Text)`
  font-size: 12px;
  color: #6d6d6d;
  border-top: 1px solid #e0e0e0;
  padding: 12px 0;
`;

const date = new Date()
const year = date.getFullYear();

const Footer: FC = () => (
  <Section boxOffset={false} bleed={false} variant="white">
    <StyledFooterWrapper>
      <StyledFooterIcon src={require("../../assets/images/care4it.png")} />
      <FooterText>Copyright {year} - All rights reserved</FooterText>
    </StyledFooterWrapper>
  </Section>
);

export default Footer;
