import React, { FC } from "react";
import { Box, Text } from "@myonlinestore/bricks";
import Title from "../Title";
import Link from "../Link";

const TeamViewer: FC = () => {
  return (
    <>
      <Box direction="column" grow={1} justifyContent="center">
        <Title>Hulp op afstand</Title>
        <Box margin={[24, 12, 0, 0]}>
          <Text>
            Bij Care for IT zetten we graag een stapje extra. Daarom gebruiken
            we TeamViewer: veilige software waarmee we eenmalig live kunnen
            meekijken en handelingen kunnen uitvoeren op uw computer. <br /> Zo
            kunnen we u beter helpen en kunt u snel weer aan de slag!
          </Text>
        </Box>
        <Box margin={[24, 0, 0]}>
          <Text>
            <Link
              href="https://www.teamviewer.com/nl/download"
              rel="noopener noreferrer"
              target="_blank"
            >
              Download TeamViewer
            </Link>
          </Text>
        </Box>
      </Box>
      <Box margin={[12, 0, 12]} padding={[12, 6]} minWidth="50%" grow={1}>
        <img
          src={require("../../assets/images/TeamViewer_logo_2.svg")}
          alt="TeamViewer"
          style={{
            maxWidth: "100%",
            maxHeight: "100px",
            height: "auto",
            margin: "auto"
          }}
        />
      </Box>
    </>
  );
};

export default TeamViewer;
