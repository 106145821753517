import styled from "styled-components";

const Link = styled.a`
    color: #101010;
    letter-spacing: .35px;
    transition: all 150ms;

    &:hover {
      color: #996299;
    }
`;

export default Link;
