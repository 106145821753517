const partners = [
  {
    src: require("../../assets/images/intel.png"),
    url: "https://www.intel.com/content/www/us/en/homepage.html",
    alt: "Intel"
  },
  {
    src: require("../../assets/images/gdata.png"),
    url: "https://www.gdata.nl",
    alt: "G-Data"
  },
  {
    src: require("../../assets/images/secpoint.png"),
    url: "https://www.secpoint.com",
    alt: "Secpoint"
  },
  {
    src: require("../../assets/images/microsoft.webp"),
    url: "http://microsoft.com",
    alt: "Microsoft"
  },
  {
    src: require("../../assets/images/lancom.svg"),
    url: "https://www.lancom-systems.com",
    alt: "Lancom"
  },
  {
    src: require("../../assets/images/Altaro.png"),
    url: "https://www.altaro.com",
    alt: "Altaro"
  },
  {
    src: require("../../assets/images/hp.png"),
    url: "https://www8.hp.com",
    alt: "hp"
  },
  {
    src: require("../../assets/images/hikvision.png"),
    url: "https://www.hikvision.com/",
    alt: "hikvision"
  }
];

export default partners;
