import React, { FC } from 'react'
import Partner from './Partner'
import { Box } from '@myonlinestore/bricks'
import partners from './partners'
import Title from '../Title'
import styled from 'styled-components'

const StyledPartnerWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 24px;
    row-gap: 24px;
    margin: 24px auto 12px auto;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    flex-grow: 1;
    max-width: 1400px;
    width: 100%;
    margin-top: 24px;

    @media screen and (max-width: 960px) {
        column-gap: 12px;
        row-gap: 12px;
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 600px) {
        column-gap: 6px;
        row-gap: 6px;
    }
`;

const Partners: FC = (): JSX.Element => (
    <Box grow={1} direction="column">
        <Box margin={[0, 'auto']}>
            <Title textAlign="center" as="h3">
                Partners van Care for IT
            </Title>
        </Box>
        <StyledPartnerWrapper>
            {partners.map((partner, index) => (
                <Partner
                    key={index}
                    src={partner.src}
                    href={partner.url}
                    alt={partner.alt}
                />
            ))}
        </StyledPartnerWrapper>
    </Box>
)

export default Partners
