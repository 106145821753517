import styled from "styled-components";

const Textarea = styled.textarea`

  font-family: "Arial";
  width: 100%;
  border: none;
  padding: 6px;
  outline: none;
  height: 120px;
  font-size: 13px;
  resize: vertical;
  margin: 6px 0 6px 0;
  box-sizing: border-box;
  letter-spacing: .55px;
`;

export default Textarea;
