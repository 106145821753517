import React, { FC } from 'react'
import styled from 'styled-components'
import { Heading } from '@myonlinestore/bricks'
import { PropsType } from '@myonlinestore/bricks/dist/components/Heading'

const StyledHeading = styled(Heading)`
    @import url(https://fonts.googleapis.com/css?family=Quicksand);
    font-family: 'Quicksand', sans-serif;
    font-weight: normal;
`

const Dot = styled.span`
    color: #996299;
`

const Title: FC<PropsType> = props => (
    <StyledHeading as="h2" {...props}>
        {props.children}
        <Dot>.</Dot>
    </StyledHeading>
)

export default Title
