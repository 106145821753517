import React, { FC, useState } from "react";
import { Box, Spinner, Button } from "@myonlinestore/bricks";
import Input from "../Form/Input";
import Textarea from "../Form/Textarea";

const ContactForm: FC = () => {
  const [loading, setLoading] = useState(false);

  const sendForm = () => {
    setLoading(true);
  };

  if (loading === true) {
    return (
      <Box grow={1} margin={["auto"]} alignItems="center" height="200px">
        <Box grow={1} height="50px">
          <Spinner></Spinner>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Input placeholder="Naam" type="text" title="Naam" />
      <Input placeholder="Email" type="text" title="Email" />
      <Input placeholder="Telefoonnummer" type="text" title="Telefoonnummer" />
      <Textarea placeholder="Bericht" />
      <Button
        title="Versturen"
        variant="plain"
        onClick={sendForm}
      >
        Versturen
      </Button>
    </>
  );
};

export default ContactForm;
