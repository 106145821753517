import React, { FC } from "react";
import styled from "styled-components";

const StyledMain = styled.div`
    @media screen and (max-width: 960px) {
        margin-top: 12px;
    }
`;

const Main: FC = props => (
      <StyledMain>
        {props.children} 
      </StyledMain>
  );
  
  export default Main;
