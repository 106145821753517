import React, { FC, useEffect, useState, useRef } from 'react'
import { Text, Box } from '@myonlinestore/bricks'
import Title from '../Title'
import ContactForm from '../ContactForm'
import styled from 'styled-components'

const StyledContactInfo = styled(Box)`
    flex-direction: column;
    margin: 0 auto;
    flex-grow: 1;
    padding: 24px;
    min-height: 420px;

    p {
        line-height: 24px;
    }

    @media screen and (max-width: 960px) {
        margin: 36px 0;
    }
`

const StyledCanvas = styled.canvas`
    height: 150px;
`

const Contact: FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [smallScreen, setSmallScreen] = useState<boolean>(true)

    useEffect(() => {
        renderContactCanvas()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', updateContactCanvas)
        return () => window.removeEventListener('resize', updateContactCanvas)
    })

    const updateContactCanvas = () => {
        if (window.innerWidth > 512 && smallScreen) {
            setSmallScreen(false)
            renderContactCanvas()
        }

        if (window.innerWidth < 512 && !smallScreen) {
            setSmallScreen(true)
            renderContactCanvas()
        }
    }

    const renderContactCanvas = () => {
        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d')
            const offset = window.innerWidth > 512 ? 260 : 90

            if (ctx) {
                ctx.fillStyle = '#000'
                ctx.font = '15px Arial'
                ctx.fillText(window.innerWidth > 512 ? 'Emailadres' : 'Email', 0, 15)
                ctx.fillText('Adres', 0, 40)

                ctx.fillText('info@careforit.nl', offset, 15)
                ctx.fillText('Kerkwijksekade 1-B', offset, 40)
                ctx.fillText('Kerkwijk', offset, 65)
                ctx.fillText('5315 AP', offset, 90)
            }
        }
    }

    return (
        <>
            <StyledContactInfo>
                <Title as="h3">Contact</Title>
                <Box direction="column" margin={[24, 0, 0, 0]}>
                    <Box justifyContent="space-between" margin={[6, 0]}>
                        <Text>
                            <b>Care for IT</b>
                        </Text>
                    </Box>
                    <Box justifyContent="space-between" margin={[6, 0]}>
                        <StyledCanvas
                            ref={canvasRef}
                            width={window.innerWidth > 512 ? '400px' : '200px'}
                            height="150px"
                            id="contactCanvas"
                        ></StyledCanvas>
                    </Box>
                </Box>
            </StyledContactInfo>
            <Box
                grow={1}
                padding={[24]}
                direction="column"
            >
                <Title as="h3">Contactformulier</Title>
                <Box direction="column" margin={[24, 0]}>
                    <ContactForm />
                </Box>
            </Box>
        </>
    )
}

export default Contact
