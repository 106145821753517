import React, { FC } from "react";
import styled from "styled-components";

const StyledPartner = styled.a`
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 12px #e0e0e0;
  display: flex;
  flex: 0 0 auto;
  max-height: 150px;
  min-height: 100px;
  min-width: 150px;
  padding: 26px;
  transform: scale(0.95);
  transition: all 150ms;

  &:hover {
    transform: scale(1);
  }

  img {
    display: block;
    height: auto;
    margin: auto;
    max-height: 80%;
    max-width: 80%;

    @media screen and (max-width: 600px) {
      max-width: 60%;
      max-height: 60%;
    }
  }

  @media screen and (max-width: 960px) {
    padding: 12px;
  }

  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

type PropsType = {
  alt: string;
  href: string;
  src: string;
};

const Partner: FC<PropsType> = ({ href, src, alt }) => (
  <StyledPartner target="_blank" href={href}>
    <img src={src} alt={alt} />
  </StyledPartner>
);

export default Partner;
